@import "./variables.scss";
@import "./fonts.scss";
@import "./components/video-embed";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: #363636;
}

h1 {
  font-family: "Montserrat";
  font-size: 5.5rem;
  text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.9);
  letter-spacing: 1px;
  line-height: 1;
  margin-top: 0.3em;
}

h2 {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1.7rem;
}

h3 {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1.8rem;
  color: #626b80;
  line-height: 2.4rem;
}
h4 {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1.4rem;
  color: #626b80;
  line-height: 2.4rem;
}

p {
  font-family: "Montserrat";
  margin-top: 0;
  margin-bottom: 1.25em;
}

a,
a:visited,
a:active {
  color: $color-text;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.clickable {
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.inverted {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.subdued {
  color: $color-text-lightest;
  font-size: $tiny-font-size;
  text-align: center;
  margin-bottom: 2em;
  &.left {
    text-align: left;
    margin-top: 0.75em;
  }
}

.description {
  color: $color-text-light;
  font-size: 1.5rem;
}

.message {
  text-align: center;
}

.btn-info {
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.2em;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  mask: url(/images/icon-info.svg) center / 80% no-repeat;
  background-color: $color1;
}

*[data-loading="true"] {
  background-image: url(/images/loader-puff.svg);
  background-position: center center;
  background-size: 80%;
  background-repeat: no-repeat;
}

*[data-invisible="true"] {
  opacity: 0;
  pointer-events: none;
}

*[data-hidden="true"] {
  display: none !important;
}

button {
  background-color: #363636;
  border-radius: 1rem;
  font-weight: 600;
  font-size: 2rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 0 1.5em;
  margin: 0 auto;
  height: 4.5rem;
  line-height: 0.7;
  display: block;
  width: 100%;
  max-width: 16em;
  border: none;
  flex-shrink: 0;
  &:hover:not([disabled]) {
    cursor: pointer;
    background: lighten(#363636, 10%);
  }

  &.chunky {
    height: 5.5rem;
    font-size: 2.2rem;
    padding: 0.5rem 1.5em 0.5rem;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
}

/*
.back-button {
  position: absolute;
  z-index: 100;
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
  margin: 0.5rem;
  background: url(/images/Button/Left/Active.svg) no-repeat center;
  background-size: 70%;
  &:hover {
    background-image: url(/images/Button/Left/Hover.svg);
    background-size: contain;
  }
}
*/

header {
  position: relative;
  text-align: center;
  width: 100%;
  padding: 1.5rem;
  h2 {
    font-size: 2.5rem;
  }
}

.main {
  display: flex;
  overflow: hidden;
  height: 100%;

  background: url(/images/bg-stripes-small.svg) top right / 67vw auto no-repeat, url(/images/hero-3.png) top 8rem left 2.5rem / calc(100vw - 7.5rem) auto no-repeat,
    #0049D5;
  &.transforming {
    background: #0049D5;
  }
  &.alt {
    background: url(/images/hero-3b.png) top 2rem left 2.5rem / calc(100vw - 7.5rem) auto no-repeat,
      #0049D5;
  }

  &[data-show-intro="1"] {
    #wrapper {
      margin-top: auto;
      margin-bottom: 25vh;
      /*height: calc(32.5% + 4rem);
      height: calc((100vh - ((100vw - 10rem)*1.16)) - 8rem);*/
      min-height: 26rem;
      .content .slideWrapper .slide {
        overflow: hidden;
      }
    }
  }

  &[data-active="2"] .slideWrapper {
    transform: translate3d(-25%, 0, 0);
  }
  &[data-active="3"] .slideWrapper {
    transform: translate3d(-50%, 0, 0);
  }
  &[data-active="4"] .slideWrapper {
    transform: translate3d(-75%, 0, 0);
  }

  &,
  > #wrapper {
    position: relative;
    width: 100%;
    min-width: 320px;
  }

  > #wrapper {
    transition: all 0.6s ease-in-out;
    height: auto;
    margin-bottom: 4rem;

    #logo-kdbb {
      margin: auto;
      display: block;
      width: 40%;
      flex-grow: 1;
      position: relative;
      img {
        width: 100%;
        vertical-align: middle;
      }
    }
    .content {
      .slideWrapper {
        display: flex;
        flex-wrap: wrap;
        width: 400%;
        transition: height 0.3s ease-in;
        .slide {
          height: 100%;
          display: flex;
          flex-direction: column;
          > .wrapper {
            width: 100%;
            height: 100%;
            justify-content: space-between;
            > section {
              height: 100%;
              overflow: auto;
            }
          }
          &:not(:nth-of-type(1)) {
            display: flex;
            flex-direction: column;
            overflow: auto;
            position: absolute;
            opacity: 0;
            height: 100%;

            > .wrapper {
            }
          }
          /*position: relative;*/
          padding: $padding;
          /*           height: 100%;
 */
          width: 25%;
          background-color: #fff;
          #intro {
            transition: opacity 0.3s ease-in;
            text-align: center;
          }
        }
      }

      border-radius: 2rem;
      overflow: hidden;
      /* fix safari not clipping the rounded corners */
      backface-visibility: hidden;
      transform: translate3d(0, 0, 0);

      margin: 1.25rem;
      /* max-height: calc(100% - 2.5rem); */
      position: relative;
      /*margin-top: calc(16rem + calc(80%*1/5.9));*/
      color: #626b80;
      font-size: 1.7rem;
    }

    @media screen and (min-width: 375px) {
      background-size: 60% auto;

      #logo-kdbb {
        img {
          margin-bottom: 2rem;
        }
      }
    }
  }

  footer {
    height: 12.5rem;
    width: 100%;
    padding: 2.5rem 0;
    text-align: center;

    @media screen and (max-width: 500px) {
      height: 5.5rem;
      padding: 0 2em;
    }
    img {
      object-fit: contain;
      display: inline-block;
      margin: 0;
      height: 100%;
      padding-bottom: 1.5rem;
      margin-top: 1.5rem;
    }
  }
}

@media screen and (max-width: 500px) {
  #root > .wrapper {
    #wrapper {
      margin-bottom: 7rem;
    }

    &:not([data-active="1"]) {
      #wrapper footer {
        display: none;
      }
    }
  }
}

[data-bigscreen="1"] {
  background: url(/images/bg-stripes.svg) top right / 60vw auto no-repeat, url(/images/hero-3.png) bottom left 3vw / auto clamp(68vh, 50vw, 88vh) no-repeat, #0049D5;

  @media screen and (min-height: 760px) and (max-width: 920px) {
    background: url(/images/bg-stripes.svg) top right / 70vw auto no-repeat, url(/images/hero-3.png) center left 3vw / auto 590px no-repeat, #0049D5;
  }

  @media screen and (max-width: 820px) {
    background: url(/images/bg-stripes.svg) top right / 80vw auto no-repeat, url(/images/hero-3.png) center left 3vw / auto 340px no-repeat, #0049D5;
  }

  > .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 7rem;
    position: absolute;
    right: 0;
    .main {
      margin-top: -90px;
      width: 380px;
      min-height: 764px;
      background: transparent;
    }
  }
}

.hg-theme-default {
  font-size: 3rem;
  font-family: inherit;
  font-weight: 600;
  padding: 0.5em;
  background-color: #d1d4db;
  .hg-button {
    &:active {
      background-color: #868a8d;
    }
    height: 2em;
    margin: 0 0.25em;
    &.hg-standardBtn {
      max-width: 2.5em;
    }
  }
  .hg-row {
    justify-content: center;
    padding: 0.25em 0;
    &:first-of-type {
      padding-bottom: 0.75em;
      margin-bottom: 0.5em;
      .hg-button-bksp {
        max-width: 3.75em;
        margin-left: 2em;
        background: url(/images/icon-kb-backspace.svg) center / auto 40% no-repeat;
        background-color: #abb1bd;
        &:active {
          background-color: #868a8d;
        }
        span {
          display: none;
        }
      }
      border-bottom: 1px solid #868a8d;
    }
  }
}
