@import "./variables.scss";
@import "~normalize.css/normalize";

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 62.5% * 0.9;
	line-height: 1.6;
	/*@media screen and (max-width: 420px) {
		font-size: 62.5%*0.9;
	}*/
	@media screen and (max-width: 360px) {
		font-size: 62.5% * 0.8;
	}
}

#root {
	font-size: 1.5rem;
	line-height: 1.4;
	font-weight: 400;
	height: 100%;
	width: 100%;
	color: $color-text;
	position: absolute;
	margin: 0;
	font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
		"Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	> .wrapper {
		> footer {
			position: fixed;
			bottom: 0;
			height: 4rem;
			width: 100%;
			padding: 0 2em;
			color: #fff;
			text-align: center;
			display: flex;
			flex-direction: row-reverse;
			.btn-info {
				background-color: #fff;
			}

			.disclaimer,
			.contact {
				color: #fff;
				cursor: pointer;
				font-size: 1.8rem;
				font-weight: 600;
				text-decoration: none;
				display: inline-block;
				margin-left: 0.5em;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
#root,
#root > .wrapper,
#root > .wrapper > .wrapper {
	height: 100%;
}

#root > .wrapper[data-kioskmode="1"] {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	background: url(/images/hero-3.png) bottom left 6vw / auto 74vh no-repeat, #0049D5;

	> .wrapper {
		width: 45%;
		position: static;
		height: auto;
		flex-grow: 1;
		> .main {
			transition: min-height 0.3s ease;
			width: 400px;
			min-height: 35rem;
			&.alt {
				min-height: 72rem;
			}
			#wrapper {
				margin-bottom: 11rem;
			}

			&[data-show-intro="1"] #wrapper {
				min-height: 36rem;
			}
		}

		.licence-plate {
			font-size: 5.5rem;
		}

		.subdued {
			font-size: 1.5rem;
		}

		#results .monthly-reminder {
			display: none;
		}
	}
	zoom: 1.15;

	footer {
		bottom: 7rem;
	}

	.keyboard {
		z-index: 2;
		width: 100%;
		transition: min-height 0.3s ease, height 0.3s ease;
		min-height: 150px;

		&[data-kbshow="false"] {
			height: 0;
			min-height: 0;
			overflow: hidden;
		}
	}

	.btn-back {
		width: 2em;
		height: 2em;
		font-size: 2rem;
		background: url(/images/Button/Back/Active.svg) center / auto 75% no-repeat;
		width: 5em;
		height: 5em;
		font-size: 2rem;
		cursor: pointer;
		position: absolute;
		left: 0;
		&:focus,
		&:hover {
			background-image: url(/images/Button/Back/Hover.svg);
			/* filter: drop-shadow(0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.3)); */
		}
	}

	&[data-active="1"] {
		.btn-back {
			display: none;
		}
	}
}

.hide,
[data-visibility="hide"] {
	display: none;
}
.hidden {
	opacity: 0;
	pointer-events: none;
}
