@import "../variables.scss";

select {
    display: block;
    overflow: hidden;
    font-family: sans-serif;
    color: $color-text-light;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #eeeff2;
    box-shadow: none;
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    display: flex;
    flex-grow: 1;
    background: url(/images/arrow-down.svg) right 1em center/auto 0.5em no-repeat;
    padding-right: 2.5em;
}

select::-ms-expand {
    display: none;
}
select:hover {
    border-color: #888;
}
select:focus {
    border-color: #aaa;
    color: #222;
    outline: none;
}
select::-moz-focus-inner {
    border: 0;
}

select option {
    font-weight: normal;
}
