@import "../variables";

#results {
	> .wrapper {
		> section {
			height: 100%;
			display: flex;
			flex-direction: column;
		}
	}
	display: flex;
	flex-direction: column;
	.how-to {
		margin: 1.5em 0;
		font-size: 1.8rem;
		transition: opacity 0.3s ease-in 2.5s;
		text-align: center;
		cursor: pointer;
	}

	.monthly-reminder {
		margin-top: auto;
		cursor: pointer;
		background-color: #eeeff2;
		display: flex;
		justify-content: center;
		padding: 0.75em 0;
		border-radius: 1rem;
		opacity: 0;
		transition: opacity 0.6s;
		flex-shrink: 0;
		.text {
			display: block;
			max-width: 18em;
			flex-grow: 1;
			display: flex;
			&:before {
				display: flex;
				flex-shrink: 1;
				align-self: center;
				content: "";
				display: inline-block;
				flex-shrink: 1;
				width: 6em;
				height: 100%;
				margin-right: 0.75em;
				background: url(/images/icon-reminder.svg) center / contain no-repeat;
			}
		}
		&[data-invisible="0"] {
			opacity: 1;

			.text:before {
				animation: shake 0.5s 3 8s;
			}
		}
	}
	#result {
		display: block;
		padding-bottom: 33%;
		position: relative;
		width: 100%;
		margin-bottom: 2.5em;

		#balloons {
			position: absolute;
			top: calc(100%);

			.wrapper {
				position: absolute;
				.balloon {
					opacity: 0;
					animation-fill-mode: forwards;
					position: relative;
					padding: 0.3em 0.5em;
					line-height: 1;
					font-size: 1.6rem;
					background: $color1;
					border-radius: 1em;
					color: #fff;
					font-weight: bold;
					white-space: nowrap;

					&:before {
						content: "";
						display: block;
						position: absolute;
						width: 1em;
						height: 0.5em;
						top: -0.5em;
						left: calc(50% - 0.5em);
						clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
						background-color: $color1;
					}
				}
			}
		}

		/* svg animations */

		@keyframes show {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		@keyframes pulse {
			0% {
				transform: scale(0.95);
				box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
			}

			70% {
				transform: scale(1);
				box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
			}

			100% {
				transform: scale(0.95);
				box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
			}
		}

		@keyframes flicker {
			0% {
				opacity: 0;
			}
			2% {
				opacity: 1;
			}
			3% {
				opacity: 0;
			}
			4% {
				opacity: 1;
			}
			5% {
				opacity: 0;
			}
			6% {
				opacity: 1;
			}
			100% {
				opacity: 1;
			}
		}

		@keyframes shake {
			0% {
				transform: translate(1px, 1px) rotate(0deg);
			}
			10% {
				transform: translate(-1px, -2px) rotate(-1deg);
			}
			20% {
				transform: translate(-3px, 0px) rotate(1deg);
			}
			30% {
				transform: translate(3px, 2px) rotate(0deg);
			}
			40% {
				transform: translate(1px, -1px) rotate(1deg);
			}
			50% {
				transform: translate(-1px, 2px) rotate(-1deg);
			}
			60% {
				transform: translate(-3px, 1px) rotate(0deg);
			}
			70% {
				transform: translate(3px, 1px) rotate(-1deg);
			}
			80% {
				transform: translate(-1px, -1px) rotate(1deg);
			}
			90% {
				transform: translate(1px, 2px) rotate(0deg);
			}
			100% {
				transform: translate(1px, -2px) rotate(-1deg);
			}
		}

		svg {
			height: 100%;
			overflow: visible;
			width: 100%;
			position: absolute;
			top: 0px;
			left: 0px;

			#Group-7 {
				animation-fill-mode: forwards;
			}
		}
	}
}
