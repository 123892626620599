@import '../variables';
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 6rem;
  height: 3rem;

    /* Hide default HTML checkbox */
    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
            background-color: $color2;
            background-image: url(/images/switch-on.svg);
            background-position: left 20% center;
        }

        &:focus + .slider {
            box-shadow: 0 0 1px $color1;
        }

        &:checked + .slider:before {
            transform: translateX(3rem);
        }
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-error;
        background-size: 60% contain;
        background-repeat: no-repeat;
        background-image: url(/images/switch-off.svg);
        background-position: right 20% center;

        transition: background-color .4s;

        /* Rounded sliders */
        &.round {
            border-radius: 3rem;
            &:before {
                border-radius: 50%;
            }
        }

        &:before {
            position: absolute;
            content: "";
            height: 2.6rem;
            width: 2.6rem;
            left: .2rem;
            bottom: .2rem;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }
    }
}