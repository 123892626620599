#manual-input {
	label {
		display: block;
		margin-bottom: 1em;
	}

	@media screen and (max-width: 375px) {
		font-size: 1.45rem;
	}
}
