.licence-plate {
	height: 1.2em;
	width: 6em;
	margin: 0.25em auto 0.75em;
	display: flex;
	border: 1px solid #000;
	border-radius: 0.1em;

	font-size: 3.5rem;
	line-height: 1.2;
	background-color: #f2ba00;
	font-family: Kenteken, monospace;
	transition: background-color 0.3s ease, text-shadow 0.3s ease;

	img {
		display: block;
		height: 100%;
	}
	input {
		position: relative;
		top: 0.12em;
		line-height: 1.2;
		width: 5.3em;
		background: transparent;
		display: block;
		border: none;
		appearance: none;
		outline: 0;
		text-align: center;
		text-transform: uppercase;
		text-shadow: -0.11rem -0.11rem 0.11rem rgba(255, 255, 255, 0.75);
		letter-spacing: -0.05em;
		white-space: nowrap;
		color: rgb(17, 17, 17);
	}
	input::placeholder {
		line-height: inherit;
		color: rgba(17, 17, 17, 0.65);
		text-shadow: -0.11rem -0.11rem 0.11rem rgba(255, 255, 255, 0.25);
	}
}
.licence-plate:focus {
	background-color: #ffc70d;
	outline: 0;
	text-shadow: 0 0 #ffe153;
}
