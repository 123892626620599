@import "../variables.scss";

#car-specs {
	display: flex;
	flex-direction: row;

	.selection {
		flex-grow: 1;
		position: relative;
		&:before {
			content: "";
			display: block;
			position: absolute;
			border-left: 2px solid #eeeff2;
			height: calc(100% - 3em);
			left: 0.4em;
			top: 1.5em;
			bottom: 1.5em;
		}
	}

	.check {
		&:after {
			content: "";
			display: block;
			position: absolute;
		}

		&:after {
			left: -2.1em;
			width: 1.2em;
			height: 1.2em;
			box-sizing: border-box;
		}

		&[data-checked="0"]:after {
			background-color: #b1b8c3;
			border: 0.25em #fff solid;
			border-radius: 50%;
			left: -2.15em;
		}

		&[data-checked="1"]:after {
			background: #fff url(/images/icon-check.svg) center / 70% no-repeat;
		}

		.checkmark {
			display: block;
			width: 1em;
			height: 1em;
		}

		margin-left: 2em;
		position: relative;
		transition: 1s;
		background-position: left top;
		background-size: 3em auto;
		background-repeat: no-repeat;
		padding-left: 4em;
		padding-top: 0.5em;
		min-height: 5em;
		margin-top: 1em;

		&.car {
			background-image: url(/images/icon-car.svg);
			&:after {
				top: 1.5em;
			}
			padding-top: 0;
			margin-top: 0;
		}

		&.tyres {
			background-image: url(/images/icon-wheel.svg);
			&:after {
				top: 1em;
			}
			.tyre-size {
				display: block;
				margin-top: 1.5em;

				select {
					font-size: 0.9em;
				}
			}
		}

		&.warmed-check {
			display: flex;
			background-image: url(/images/icon-steeringwheel.svg);
			&:after {
				top: 1em;
			}
			transition: 1s;

			h2 {
				flex-grow: 1;
				margin-right: 1em;
			}

			.switch {
				flex-shrink: 0;
			}
		}
	}

	button {
		flex-shrink: 0;
	}
}
