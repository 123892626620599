.modal-cont {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 1000;
	top: 0;
	background-color: rgba(0,0,0,.75);
	display: flex;
	transition: opacity .1s ease-in;
	padding: 5rem;

	.modal {
		display: flex;
		position: relative;
		max-width: 360px;
		max-height: calc(100% - 20rem);
		margin: auto;
		background-color:#fff;
		min-width: 20rem;
		min-height: 30rem;
		border-radius: 2rem;
		padding-top: 3rem;
		padding-bottom: 2rem;

		.content {
			padding: 0 2rem;
			text-align: center;
			overflow: auto;

			h2 {
			  margin-bottom: .625em;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		.btn-close {
			position: absolute;
			cursor: pointer;
			top: 1rem;
			right: 1rem;
			width: 2rem;
			height: 2rem;
			background: url(/images/popup-close.svg) center / contain no-repeat;
		}

	}
}
