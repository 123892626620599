$color-text: #333647;
$color-text-light: #606A7B;
$color-text-lightest: lighten($color-text, 40%);
$color1: #363636;
$color2: #00B38A;
$color-light: #B1B8C3;
$color-error: #FF5E61;

$margin: 2.5%;
$padding: 1.25em;
$small-font-size: 1.4rem;
$tiny-font-size: 1.3rem;
$smaller-font-size: 0.9em;
$body-font-size: 2rem;
